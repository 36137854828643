<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="true"
    :titleText="$t('general.handHistory')"
    class="history"
  >
    <HandHistory />
  </BaseWindow>
</template>

<script>
//

export default {
  name: "HistoryWindow",
  components: {
    BaseWindow: () => import("@/components/Misc/GlobalWindows/BaseWindow"),
    HandHistory: () => import("@/components/Misc/GlobalWindows/HandHistory"),
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "history");
    },
  },
};
</script>
<style scoped lang="scss">
.history {
  z-index: 21;
}
</style>
